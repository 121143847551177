import * as React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { useMountEffect } from 'hooks/useMountEffect'
import { useStytch } from '@stytch/react'
import { User, Session, ResponseCommon } from '@stytch/vanilla-js'

const AuthContext = React.createContext<{
  session: Session | null
  user: User | null
  isLoading: boolean
  logout: (() => Promise<ResponseCommon>) | null
}>({
  session: null,
  isLoading: false,
  user: null,
  logout: null,
})

export function AuthProvider({ children }: { children: React.ReactElement }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [session, setSession] = React.useState<Session | null>(null)
  const [user, setUser] = React.useState<User | null>(null)
  const stytchClient = useStytch()

  stytchClient.session.onChange((session) => {
    setSession(session)
    setIsLoading(false)
  })
  stytchClient.user.onChange((user) => setUser(user))

  useMountEffect(() => {
    setIsLoading(true)
    const session = stytchClient.session.getSync()
    setSession(session)
    const user = stytchClient.user.getSync()
    setUser(user)
    setIsLoading(false)
  })

  const value = {
    session,
    isLoading,
    user,
    logout: stytchClient.session.revoke,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext)
}

export function RequireAuth({ children }: { children: React.ReactElement }) {
  const { session, isLoading } = useAuth()
  const location = useLocation()
  if (isLoading) {
    return null
  }

  console.log('---')
  console.log(session)
  console.log('---')

  if (!session) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={`/login${location.search}`}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}
