export const getApiUrl = (endpoint: string): string => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:54321/functions/v1/' + endpoint
    // return 'https://mppkguekytvrhzkovaxz.functions.supabase.co/' + endpoint
  }
  return 'https://mppkguekytvrhzkovaxz.functions.supabase.co/' + endpoint
}

export function getCurrentHost(): string {
  return window.location.protocol + '//' + window.location.host
}

export const getBearerToken = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24ifQ.625_WdcF3KHqz5amU0x2X5WWHP-OEs_4qj0ssLNHzTs'
  }

  // priod
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1wcGtndWVreXR2cmh6a292YXh6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjI1MzM1ODksImV4cCI6MTk3ODEwOTU4OX0.wvhSjYAC1fRNQWSVVCbRaLydbjyyu61oL_3an36rLOE'
}

export const getSubdomain = (): string | null => {
  if (!window?.location?.host || window.location.host.split('.').length < 1) {
    return null
  }

  return window.location.host.split(".")[0]
}
