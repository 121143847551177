import React from 'react'
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import { useQueryParams } from 'hooks/useQueryParams'
import { isAccountIdValid, useAccountDetails } from 'hooks/useAccountDetails'
import { Sidebar } from 'Sidebar'
import { RequireAuth, useAuth } from './Auth'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import {
  CalendarTodayRounded,
  DynamicFormRounded,
  ReceiptLongRounded,
} from '@mui/icons-material'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'

export function PageWrapperLegacy({
  content,
  skipAuth,
}: {
  content: JSX.Element
  skipAuth?: boolean
}): JSX.Element {
  const queryParams = useQueryParams()
  const accountDetails = useAccountDetails()
  const accountId = accountDetails.accountId
  const pageContent = (
    <ThemeProvider
      theme={(theme: any) => {
        return createTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: accountDetails.primaryColors,
          },
        })
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '4fr 6fr',
          gridTemplateRows: '100vh',
          // todo: add gridTemplateAreas
        }}
      >
        <Box
          sx={{
            paddingTop: 64,
            paddingLeft: 80,
            paddingRight: 80,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <Sidebar accountDetails={accountDetails} />
        </Box>
        <Box
          sx={{
            paddingTop: 64,
            marginLeft: 80,
            marginRight: 80,
          }}
        >
          {content}
        </Box>
      </div>
    </ThemeProvider>
  )
  if (skipAuth) {
    return pageContent
  }

  return <RequireAuth>{pageContent}</RequireAuth>
}

const drawerWidth = '380px'

const MenuItems = [
  {
    label: 'Appointments',
    icon: <CalendarTodayRounded sx={{ color: 'primary.contrastText' }} />,
    to: '/appointments',
  },
  {
    label: 'Payments',
    icon: <ReceiptLongRounded sx={{ color: 'primary.contrastText' }} />,
    to: '/payments',
  },
  {
    label: 'Files',
    icon: <UploadFileRoundedIcon sx={{ color: 'primary.contrastText' }} />,
    to: '/files',
  },
  {
    label: 'Profile',
    icon: <DynamicFormRounded sx={{ color: 'primary.contrastText' }} />,
    to: '/profile',
  },
]

export function PageWrapperInner({
  content,
  skipAuth,
}: {
  content: JSX.Element
  skipAuth?: boolean
}) {
  const queryParams = useQueryParams()

  const { logout, session, isLoading } = useAuth()
  const navigate = useNavigate()
  const theme = useTheme()
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('sm'))
  const isAuthenticated = session != null

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const accountDetails = useAccountDetails()
  const accountId = accountDetails.accountId

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        height: '100%',
        pl: 32,
        pt: 32,
        pr: 32,
      }}
    >
      <Stack spacing={32}>
        <Stack
          direction="row"
          spacing={8}
          alignItems="flex-start"
          sx={{ fontSize: 20 }}
        >
          <Box sx={{ pt: 4 }}>{accountDetails.icon}</Box>
          <Stack spacing={8}>
            <Typography variant="h6" fontWeight="bold">
              {accountDetails.name}
            </Typography>
            <Typography variant="body1">
              {accountDetails.description}
            </Typography>
          </Stack>
        </Stack>
        {!isLoading && isAuthenticated && (
          <Box>
            <Divider sx={{ bgcolor: 'primary.contrastText' }} />
            <List>
              {MenuItems.map((item) => (
                <ListItem key={item.label} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(item.to + '?' + queryParams.toString())
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Stack>
    </Box>
  )

  const pageContent = (
    <ThemeProvider
      theme={(theme: any) => {
        return createTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: accountDetails.primaryColors,
          },
        })
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {!greaterThanMid && (
          <AppBar
            position="fixed"
            sx={{
              display: { sm: 'none' },
              width: { sm: `calc(100% - ${drawerWidth})` },
              ml: { sm: `${drawerWidth}` },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </AppBar>
        )}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="navigation items"
        >
          {!greaterThanMid && (
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: { base: '50vw', sm: '30vw' },
                },
              }}
            >
              {drawer}
            </Drawer>
          )}

          {greaterThanMid && (
            <Drawer
              variant="permanent"
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          )}
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth})` },
          }}
        >
          <Box
            sx={{
              paddingTop: 32,
              paddingLeft: 64,
              paddingRight: 64,
              paddingBottom: 32,
            }}
          >
            {!isLoading && isAuthenticated && (
              <Grid container justifyContent="flex-end">
                <Link
                  underline="hover"
                  onClick={() => {
                    logout && logout()
                  }}
                  color="secondary.Text"
                >
                  <Stack direction="row" alignItems="center" spacing={4}>
                    <Typography variant="caption" fontSize={12}>
                      Sign out
                    </Typography>
                    <LogoutRoundedIcon fontSize="inherit" />
                  </Stack>
                </Link>
              </Grid>
            )}
            <Box sx={{ pt: 20 }}>{content}</Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )

  if (skipAuth) {
    return pageContent
  }

  return <RequireAuth>{pageContent}</RequireAuth>
}

export function PageWrapper({
  content,
  skipAuth,
}: {
  content: JSX.Element
  skipAuth?: boolean
}) {
  const queryParams = useQueryParams()
  const accountDetails = useAccountDetails()
  if (accountDetails == null) {
    return (
      <Typography>
        Please specify a valid accountId in the query params.
      </Typography>
    )
  }

  return <PageWrapperInner content={content} skipAuth={skipAuth} />
}
