import React from 'react'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooks'
import SportsVolleyballOutlinedIcon from '@mui/icons-material/SportsVolleyballOutlined'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import {getSubdomain} from "../common/Utils";

export type AccountDetails = {
  accountId: string,
  name: string
  description: string
  primaryColors: {
    light: string
    main: string
    dark: string
    contrastText: string
  }
  icon: JSX.Element
  calendarLink: string
  prices: string[]
}


export const getAccountIdFromSubdomain = (subdomain: string): string | null => {
  switch (subdomain) {
    case 'griffindunn':
      return 'acct_1M9JG7Dgy2Qb3WiO'
    default:
      return null
  }
}

export const getAccountIdFromURL = () => {
  const subdomain = getSubdomain();

  if (subdomain != null) {
    const accountId = getAccountIdFromSubdomain(subdomain)
    if (accountId != null) {
      return accountId
    }
  }

  const params = new URL(window.location.href).searchParams;
  return params.get('account_id'); // "1"
}


export const isAccountIdValid = (accountId: string): boolean => {
  return (
    accountId === 'acct_1LeSweKsPGPNDX2o' ||
    accountId === 'acct_1JtHCYBfpYWWrNdo' ||
    accountId === 'acct_1M9JG7Dgy2Qb3WiO'
  )
}

// todo: fetch from server
export const useAccountDetails = (): AccountDetails => {
  const accountIdInterpolated = getAccountIdFromURL();

  if (accountIdInterpolated === 'acct_1LeSweKsPGPNDX2o') {
    return {
      accountId: accountIdInterpolated,
      name: 'Seattle Volleyball Club',
      description: 'Profession volleyball club for all ages.',
      primaryColors: {
        light: '#df78ef',
        main: '#ab47bc',
        dark: '#790e8b',
        contrastText: '#fff',
      },
      icon: <SportsVolleyballOutlinedIcon fontSize="inherit" />,
      calendarLink: 'https://calendly.com/ryanatkins609/30min',
      prices: ['price_1LlyzhKsPGPNDX2oZKIrakXA'],
    }
  }
  if (accountIdInterpolated === 'acct_1JtHCYBfpYWWrNdo') {
    return {
      accountId: accountIdInterpolated,
      name: 'Jackson Accounting & Tax',
      description: 'Serving local Seattle businesses for more than 30 years.',
      primaryColors: {
        light: '#63a4ff',
        main: '#1976d2',
        dark: '#004ba0',
        contrastText: '#fff',
      },
      icon: <LibraryBooksOutlinedIcon fontSize="inherit" />,
      calendarLink: 'https://calendly.com/vijitdhingra/30-min-quick-chat',
      prices: ['price_1KzQWgBfpYWWrNdoR7ih3kl4'],
    }
  }

  if (accountIdInterpolated === 'acct_1M9JG7Dgy2Qb3WiO') {
    return {
      accountId: accountIdInterpolated,
      name: 'Griffindunn Coaching',
      description: 'We are passionate about helping you succeed.',
      primaryColors: {
        light: '#484848',
        main: '#212121',
        dark: '#000000',
        contrastText: '#ffffff',
      },
      icon: <SelfImprovementIcon fontSize="inherit" />,
      calendarLink: 'https://calendly.com/griffindunn_coaching/45min',
      prices: ['price_1M9JRpDgy2Qb3WiOBvgHomQp'],
    }
  }

  throw new Error('Inavlid account Id')
}
