import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import Button from '@mui/material/Button'
import axios from 'axios'
import { Appointment } from '../types/Appointment'
import { getApiUrl, getBearerToken } from 'common/Utils'

export function AppointmentRow({
  appointment,
  onAppointmentCancel,
  accountId,
}: {
  appointment: Appointment
  onAppointmentCancel: () => void
  accountId: string
}): JSX.Element {
  const isPastAppointment: boolean = moment(appointment.startTime).isBefore(
    moment()
  )

  const cancelAppointment = (eventURI: string) => {
    axios
      .post(
        `${getApiUrl('cancel-customer-appointment')}`,
        {
          accountId,
          eventURI,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getBearerToken(),
          },
        }
      )
      .then(() => {
        onAppointmentCancel()
      })
  }

  return (
    <Stack
      direction="row"
      spacing={24}
      justifyContent="space-between"
      sx={{
        boxShadow: 2,
        borderRadius: 2,
        borderWidth: 2,
        padding: 20,
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {appointment.title}
        </Typography>
        <Typography variant="body1">
          {moment(appointment.startTime).format('MMM Do, hh:mm A')}
        </Typography>
      </Box>
      {!isPastAppointment && (
        <Box>
          <Button
            sx={{ width: 230 }}
            variant="outlined"
            onClick={() => {
              cancelAppointment(appointment.id)
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Stack>
  )
}
