import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {} from '@auth0/auth0-react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useQueryParams } from 'hooks/useQueryParams'
import { useAccountDetails } from 'hooks/useAccountDetails'
import { useAuth } from './Auth'

function AuthCheckout(): JSX.Element {
  const { user, isLoading, session } = useAuth()
  const isAuthenticated = session != null
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const stripePriceId = queryParams.get('stripe_price_id')
  const merchantRedirectUrl = queryParams.get('redirect_url')
  const accountDetails = useAccountDetails()
  const accountId = accountDetails.accountId

  useEffect(() => {
    localStorage.setItem(
      'checkoutData',
      JSON.stringify({ stripePriceId, merchantRedirectUrl, accountId })
    )
  }, [
    accountId,
    isLoading,
    isAuthenticated,
    navigate,
    stripePriceId,
    merchantRedirectUrl,
  ])

  if (isLoading) {
    return <Typography> Loading....</Typography>
  }

  if (!stripePriceId || !merchantRedirectUrl) {
    return <Typography> Inavlid query params</Typography>
  }

  if (!isAuthenticated) {
    return <Typography> Loading....</Typography>
  }

  if (!user?.emails[0].email) {
    throw new Error('user authenticated but email not found')
  }

  // show a screen asking if the users wants to continues as the authenticated user

  return (
    <Box sx={{ maxWidth: 464 }}>
      <Typography variant="h5">Continue as {user?.emails[0].email}?</Typography>
      <Typography variant="body2" sx={{ mt: 8 }}>
        {accountDetails.name}
        {' uses '}
        {/* todo: link to landing page */}
        <Link href="https://www.google.com/">Cascade</Link>

        {
          " to manage authorization. We'll share your email with them after you continue."
        }
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 32 }}
      >
        <Button
          type="submit"
          variant="contained"
          fullWidth
          onClick={() => {
            navigate('/auth-checkout-redirect?' + queryParams.toString())
          }}
        >
          Continue
        </Button>
      </Box>
      <Box
        sx={{ mt: 8 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          type="submit"
          variant="outlined"
          fullWidth
          onClick={() => {
            // logout({
            //   returnTo:
            //     getCurrentHost() + '/auth-checkout?' + queryParams.toString(),
            // })
          }}
        >
          Continue as a different user
        </Button>
      </Box>
    </Box>
  )
}

export { AuthCheckout }
