import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import type { Payment } from 'types/Payment'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import moment from 'moment'

function PaymentRow({ payment }: { payment: Payment }): JSX.Element {
  const paymentStatus = payment.paymentIntent.status

  return (
    <Grid container direction="row" spacing={4} sx={{ mb: 8 }}>
      <Grid item xs={4}>
        <Typography variant="body1">
          {/* todo: use product name from API */}
          {payment.lineItem.description}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">
          {moment.unix(payment.paymentIntent.created).format('MMM Do, YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Box>${(payment.paymentIntent.amount / 100.0).toFixed(2)}</Box>
      </Grid>
      <Grid item xs={3}>
        <Box>
          <Chip
            label={paymentStatus}
            color={paymentStatus === 'succeeded' ? 'success' : 'warning'}
            variant="outlined"
            size="small"
          />
        </Box>
      </Grid>
    </Grid>
  )
  // date, amount, status, product name?
}

export function PaymentsList({
  payments,
}: {
  payments: Array<Payment>
}): JSX.Element {
  return (
    <Box>
      <Box sx={{ marginBottom: 8 }}>
        <Typography variant="overline">Past Payments</Typography>
        <Divider variant="middle" />
      </Box>
      <Stack direction="column">
        {payments.map((payment) => (
          <PaymentRow key={payment.paymentIntent.id} payment={payment} />
        ))}
      </Stack>
    </Box>
  )
}
