import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useQueryParams } from 'hooks/useQueryParams'
import { useAuth } from './Auth'
import { makeCheckoutRequestAndRedirect } from 'Invoices/api'
import {useAccountDetails} from "./hooks/useAccountDetails";

function AuthCheckoutRedirect(): JSX.Element {
  const { user, session, isLoading } = useAuth()

  const isAuthenticated = session != null
  // PageWrapper validates a legitimate accountId existance
  const accountDetails = useAccountDetails()
  const accountId = accountDetails.accountId
  const customerEmail = user?.emails[0].email

  useEffect(() => {
    const getCheckoutData = (): {
      stripePriceId: string
      merchantRedirectUrl: string
      customerEmail: string
      accountId: string
    } => {
      const checkoutDataString = localStorage.getItem('checkoutData')
      localStorage.removeItem('checkoutData')
      if (!checkoutDataString) {
        throw new Error('No checkout data found')
      }

      if (!customerEmail) {
        throw new Error('No customer email found')
      }

      return { ...JSON.parse(checkoutDataString), customerEmail }
    }

    console.log(isAuthenticated)
    if (!isLoading && isAuthenticated) {
      const requestData = { ...getCheckoutData() }

      // overwrite accountId if specifid in query params
      makeCheckoutRequestAndRedirect(
        accountId ? { ...requestData, accountId } : requestData
      )
    }
  }, [accountId, isLoading, isAuthenticated, customerEmail])

  if (isLoading) {
    return <Typography> Loading...</Typography>
  }

  if (!isAuthenticated) {
    throw new Error('Not authenticated :(')
  }

  return <Typography> Loading...</Typography>
}

export { AuthCheckoutRedirect }
