import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { AuthCheckout } from 'AuthCheckout'
import { AuthCheckoutRedirect } from 'AuthCheckoutRedirect'
import { PageWrapper } from 'PageWrapper'
import ErrorBoundary from 'common/ErrorBoundary'
import { Login } from './Login'
import { AppointmentsSection } from './Appointment'
import { InvoicesList } from 'Invoices'
import { ComingSoon } from 'ComingSoon'

const theme = createTheme({
  spacing: 1,
  palette: {
    primary: {
      light: '#63a4ff',
      main: '#1976d2',
      dark: '#004ba0',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },
  },
})

const App = (): JSX.Element => {
  return (
    <Router>
      <div id="app">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary>
            <Routes>
              <Route
                path="/"
                element={<PageWrapper content={<InvoicesList />} />}
              />
              <Route
                path="/login"
                element={<PageWrapper skipAuth={true} content={<Login />} />}
              />

              <Route
                path="/auth-checkout"
                element={<PageWrapper content={<AuthCheckout />} />}
              />
              <Route
                path="/auth-checkout-redirect"
                element={<AuthCheckoutRedirect />}
              />
              <Route
                path="/appointments"
                element={<PageWrapper content={<AppointmentsSection />} />}
              />
              <Route
                path="/payments"
                element={<PageWrapper content={<InvoicesList />} />}
              />
              <Route
                path="/files"
                element={<PageWrapper content={<ComingSoon />} />}
              />
              <Route
                path="/profile"
                element={<PageWrapper content={<ComingSoon />} />}
              />
            </Routes>
          </ErrorBoundary>
        </ThemeProvider>
      </div>
    </Router>
  )
}

export default App
