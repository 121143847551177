import React from 'react'
import { Products, StytchLoginConfig } from '@stytch/vanilla-js'
import { StytchLogin, useStytchSession } from '@stytch/react'
import Stack from '@mui/material/Stack'
import { getCurrentHost } from './common/Utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

const config: StytchLoginConfig = {
  passwordOptions: {
    loginExpirationMinutes: 24 * 60,
    loginRedirectURL: getCurrentHost() + '/auth-checkout-redirect',
    resetPasswordExpirationMinutes: 365 * 24 * 60,
    resetPasswordRedirectURL: 'YOUR_PASSWORD_RESET_URL',
  },
  products: [Products.passwords, Products.otp, Products.oauth],
}

type RouterState = {
  from: { pathname: string; search: string }
}

export const Login = () => {
  const session = useStytchSession()
  const location = useLocation()
  const state: RouterState | null = location.state as RouterState
  const navigate = useNavigate()

  const from = state?.from?.pathname || '/home'
  const fromWithQuery = `${from}${state?.from?.search}`
  if (session.session) {
    navigate(fromWithQuery, { replace: true })
  }

  return (
    <Stack
      direction="column"
      spacing={32}
      justifyContent="center"
      alignItems="center"
    >
      <StytchLogin config={config} />
      <Typography
        textAlign="center"
        variant="caption"
        fontSize={14}
        sx={{ maxWidth: 400 }}
      >
        Once you log in you will be able to schedule appointments and make
        payments.
      </Typography>
    </Stack>
  )
}
