import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import type { Payment, PriceDetail } from 'types/Payment'
import Stack from '@mui/material/Stack'
import { useQueryParams } from 'hooks/useQueryParams'
import { useAuth } from '../Auth'
import { makeGetPriceDetailsRequest, makeListPaymentsRequest } from './api'
import { useAccountDetails } from 'hooks/useAccountDetails'
import { PaymentsList } from './PaymentList'
import { PricesSection } from './PricesSection'

export function InvoicesList(): JSX.Element {
  const { user, isLoading, session } = useAuth()
  const isAuthenticated = session != null

  const queryParams = useQueryParams()
  const [isLoadingPayments, setIsLoadingPayments] = React.useState(true)
  const [payments, setPayments] = React.useState<Array<Payment>>([])

  const [isLoadingPrices, setIsLoadingPrices] = React.useState(true)
  const [prices, setPrices] = React.useState<Array<PriceDetail>>([])

  // PageWrapper validates a legitimate accountId existance
  const accountDetails = useAccountDetails()
  const accountId = accountDetails.accountId

    const customerEmail = user?.emails[0].email

  useEffect(() => {
    if (!isLoading && isAuthenticated && isLoadingPayments && isLoadingPrices) {
      if (!customerEmail) {
        throw new Error('user authenticated but email not found')
      }

      makeListPaymentsRequest({
        customerEmail,
        accountId,
        onSuccess: ({ payments }) => {
          setPayments(payments)
          setIsLoadingPayments(false)
        },
      })

      makeGetPriceDetailsRequest({
        priceIds: accountDetails.prices,
        accountId,
        onSuccess: ({ priceDetails }) => {
          setPrices(priceDetails)
          setIsLoadingPrices(false)
        },
      })
    }
  }, [
    accountId,
    isLoading,
    isAuthenticated,
    customerEmail,
    accountDetails,
    isLoadingPayments,
    isLoadingPrices,
  ])

  if (!isAuthenticated) {
    throw new Error('Invoice List loaded without user being authenticated')
  }

  if (isLoading || isLoadingPayments || isLoadingPrices) {
    return <Typography>{'Loading...'}</Typography>
  }

  if (isAuthenticated && !isLoadingPayments && !isLoadingPrices) {
    return (
      <Box>
        <Stack direction="column" spacing={64}>
          <PricesSection priceDetails={prices} />
          <PaymentsList payments={payments} />
        </Stack>
      </Box>
    )
  }

  return (
    <Typography component="h3" variant="h5">
      {'Not Authenticated'}
    </Typography>
  )
}
