import type { Payment, PriceDetail } from 'types/Payment'
import { getApiUrl, getBearerToken } from 'common/Utils'

export const makeListPaymentsRequest = ({
  customerEmail,
  accountId,
  onSuccess,
}: {
  customerEmail: string
  accountId: string
  onSuccess: ({ payments }: { payments: Array<Payment> }) => void
}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getBearerToken(),
    },
    body: JSON.stringify({
      customerEmail,
      accountId,
    }),
  }
  fetch(getApiUrl('list-customer-payments'), requestOptions)
    .then((res) => res.json())
    .then(
      (result) => {
        onSuccess({ payments: result.paymentDetails })
      },
      (error) => {
        throw new Error('Encountered error: ' + JSON.stringify(error))
      }
    )
}

export const makeGetPriceDetailsRequest = ({
  priceIds,
  accountId,
  onSuccess,
}: {
  priceIds: string[]
  accountId: string
  onSuccess: ({ priceDetails }: { priceDetails: Array<PriceDetail> }) => void
}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getBearerToken(),
    },
    body: JSON.stringify({
      priceIds,
      accountId,
    }),
  }
  fetch(getApiUrl('get-price-details'), requestOptions)
    .then((res) => res.json())
    .then(
      (result) => {
        onSuccess(result)
      },
      (error) => {
        throw new Error('Encountered error: ' + JSON.stringify(error))
      }
    )
}

export const makeCheckoutRequestAndRedirect = ({
  stripePriceId,
  customerEmail,
  merchantRedirectUrl,
  accountId,
}: {
  stripePriceId: string
  merchantRedirectUrl: string
  customerEmail: string
  accountId: string
}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getBearerToken(),
    },
    body: JSON.stringify({
      priceId: stripePriceId,
      customerEmail,
      redirectUrl: merchantRedirectUrl,
      accountId,
    }),
  }
  fetch(getApiUrl('stripe-checkout'), requestOptions)
    .then((res) => res.json())
    .then(
      (result) => {
        if (!result.checkoutSessionUrl) {
          throw new Error('checkout URL not found')
        }
        window.location.replace(result.checkoutSessionUrl)
      },
      (error) => {
        throw new Error('Encountered error: ' + JSON.stringify(error))
      }
    )
    .catch((error) => {
      throw new Error('Encountered error: ' + JSON.stringify(error))
    })
}
