import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import type { PriceDetail } from 'types/Payment'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { makeCheckoutRequestAndRedirect } from './api'
import { useAuth } from 'Auth'
import { useQueryParams } from 'hooks/useQueryParams'
import { getCurrentHost } from 'common/Utils'
import {useAccountDetails} from "../hooks/useAccountDetails";

function PriceDetails({
  priceDetail,
}: {
  priceDetail: PriceDetail
}): JSX.Element {
  const { user } = useAuth()
  const customerEmail = user?.emails[0].email
  const queryParams = useQueryParams()
  const accountDetails = useAccountDetails();
  // Page wrapper validates presence of account_id
  const accountId = accountDetails.accountId;

  if (!customerEmail) {
    throw new Error('Customer Email not found in PriceDetails')
  }

  return (
    <Stack
      direction="row"
      spacing={24}
      justifyContent="space-between"
      sx={{ paddingTop: 20, paddingBottom: 20 }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {priceDetail.product.name}
        </Typography>
        <Typography variant="body1">
          ${(priceDetail.unit_amount / 100.0).toFixed(2)}
        </Typography>
      </Box>

      <Box>
        <Button
          sx={{ width: 230 }}
          variant="contained"
          onClick={() => {
            makeCheckoutRequestAndRedirect({
              stripePriceId: priceDetail.id,
              customerEmail,
              merchantRedirectUrl:
                getCurrentHost() + '/payments?' + queryParams.toString(),
              accountId,
            })
          }}
        >
          Buy
        </Button>
      </Box>
    </Stack>
  )
}

export function PricesSection({
  priceDetails,
}: {
  priceDetails: Array<PriceDetail>
}): JSX.Element {
  console.log(priceDetails)

  return (
    <Box>
      <Box sx={{ marginBottom: 8 }}>
        <Typography variant="overline">Products</Typography>
        <Divider variant="middle" />
      </Box>
      <Stack direction="column">
        {priceDetails.map((priceDetail) => (
          <PriceDetails key={priceDetail.id} priceDetail={priceDetail} />
        ))}
      </Stack>
    </Box>
  )
}
