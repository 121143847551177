import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import { AppointmentRow } from './AppointmentRow'
import axios from 'axios'
import { getApiUrl, getBearerToken } from '../common/Utils'
import { useAuth } from '../Auth'
import { useQueryParams } from '../hooks/useQueryParams'
import { Appointment } from '../types/Appointment'
import { Tab, Tabs } from '@mui/material'
import moment from 'moment'
import { useAccountDetails } from 'hooks/useAccountDetails'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function AppointmentList({
  appointments,
  onAppointmentCancel,
  accountId,
}: {
  appointments: Appointment[]
  onAppointmentCancel: () => void
  accountId: string
}): JSX.Element {
  return (
    <Stack direction="column" spacing={8} sx={{ paddingTop: 10 }}>
      {appointments.map((appointment) => (
        <AppointmentRow
          key={appointment.id}
          appointment={appointment}
          onAppointmentCancel={onAppointmentCancel}
          accountId={accountId}
        />
      ))}
    </Stack>
  )
}

function fetchCustomerAppointments({
  customerEmail,
  accountId,
  onSuccess,
}: {
  customerEmail?: string
  accountId?: string
  onSuccess: (result: any) => void
}) {
  if (!customerEmail || !accountId) {
    throw new Error('customerEmail, accountId not found in AppointmentsSection')
  }

  axios
    .post(
      `${getApiUrl('list-customer-appointments')}`,
      {
        customerEmail,
        accountId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getBearerToken(),
        },
      }
    )
    .then((result) => {
      onSuccess(result)
    })
}

export function AppointmentsSection(): JSX.Element {
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const upcomingAppointments = appointments.filter((apt) => {
    return moment(apt.startTime).isAfter(moment())
  })
  const pastAppointments = appointments.filter((apt) => {
    return moment(apt.startTime).isBefore(moment())
  })

  const { user } = useAuth()
  const customerEmail = user?.emails[0].email
  const queryParams = useQueryParams()
  const accountDetails = useAccountDetails()
  const accountId = accountDetails.accountId

  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    fetchCustomerAppointments({
      customerEmail,
      accountId,
      onSuccess: (result) => setAppointments(result.data.appointments),
    })
  }, [customerEmail, accountId])

  useCalendlyEventListener({
    onEventScheduled: () => {
      fetchCustomerAppointments({
        customerEmail,
        accountId,
        onSuccess: (result) => setAppointments(result.data.appointments),
      })
    },
  })

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab label="Schedule appointment" />
        <Tab label="Upcoming appointments" />
        <Tab label="Past appointments" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {/* <Cal
            calLink={accountDetails.calendarLink}
            config={{
              email: customerEmail,
              theme: 'light',
              width: '100%',
            }}
          /> */}

        <InlineWidget
          url={accountDetails.calendarLink}
          styles={{
            height: '500px',
          }}
          pageSettings={{
            hideEventTypeDetails: false,
            hideLandingPageDetails: true,
          }}
          prefill={{
            email: customerEmail,
          }}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {upcomingAppointments && (
          <AppointmentList
            appointments={upcomingAppointments}
            onAppointmentCancel={() => {
              fetchCustomerAppointments({
                customerEmail,
                accountId,
                onSuccess: (result) =>
                  setAppointments(result.data.appointments),
              })
            }}
            accountId={accountId}
          />
        )}
        {upcomingAppointments.length === 0 && (
          <Box sx={{ pt: 16 }}>
            <Typography>You have no upcoming appointments.</Typography>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {pastAppointments && (
          <AppointmentList
            appointments={pastAppointments}
            onAppointmentCancel={() => {
              fetchCustomerAppointments({
                customerEmail,
                accountId,
                onSuccess: (result) =>
                  setAppointments(result.data.appointments),
              })
            }}
            accountId={accountId}
          />
        )}
        {pastAppointments.length === 0 && (
          <Box sx={{ pt: 16 }}>
            <Typography>You have no past appointments.</Typography>
          </Box>
        )}
      </TabPanel>
    </Box>
  )
}
